const utils = require('./utils');

module.exports = {
  createServizioPath: (servizio, val) => {
    let nomeCompleto;
    
    let slugspecie =  utils.slugify(val);
    if (!!servizio.friendlyUrl) {
        nomeCompleto = `/${slugspecie}/${servizio.friendlyUrl}`;
    } else {
        nomeCompleto = `/${slugspecie}/`+utils.slugify(servizio.titolo);
    }
    nomeCompleto += nomeCompleto.endsWith("/") ? "" : "/";
    //console.log('url',nomeCompleto);
    return nomeCompleto;  
  },
  createServizioDiagnosticaPath: servizio => {
    let nomeCompleto;
  
    if (!!servizio.friendlyUrl) {
        nomeCompleto = `/${servizio.friendlyUrl}`;
    } else {
        nomeCompleto = `/`+utils.slugify(servizio.titolo);
    }
    nomeCompleto += nomeCompleto.endsWith("/") ? "" : "/";
    //console.log('url',nomeCompleto);
    return nomeCompleto;  
  },
  createApprofondimentoPath: servizio => {
    let nomeCompleto;
  
    if (!!servizio.friendlyUrl) {
        nomeCompleto = `/servizi/${servizio.friendlyUrl}`;
    } else {
        nomeCompleto = `/servizi/`+utils.slugify(servizio.titolo); 
    }
    nomeCompleto += nomeCompleto.endsWith("/") ? "" : "/";
    //console.log('url',nomeCompleto);
    return nomeCompleto;  
  },
  createPersonaPath: persona => {
    let nomeCompleto;
    nomeCompleto = `/staff/${persona.friendlyUrl}`;
    nomeCompleto += nomeCompleto.endsWith("/") ? "" : "/";
    //console.log('url',nomeCompleto);
    return nomeCompleto;  
  },
  createNewsPath: persona => {
    let nomeCompleto;
    nomeCompleto = `/news-ed-eventi/${persona.friendlyUrl}`;
    nomeCompleto += nomeCompleto.endsWith("/") ? "" : "/";
    //console.log('url',nomeCompleto);
    return nomeCompleto;  
  },
  createAvvisoPath: avviso => {
    let nomeCompleto;
    nomeCompleto = `/avvisi/${avviso.friendlyUrl}`;
    nomeCompleto += nomeCompleto.endsWith("/") ? "" : "/";
    //console.log('url',nomeCompleto);
    return nomeCompleto;  
  }
}

