import React from 'react'
import Helmet from 'react-helmet'
import Breadcrumbs from '../components/breadcrumbs'
import Hero from '../components/hero'
import Layout from "../components/layout"
import DocumentiUtili from '../components/documenti-utili'
import Avvisi from '../components/avvisi'
import { graphql } from "gatsby"
import Moment from 'moment';


class AreaStudenti extends React.Component {
  
  constructor(props) {
    super(props);
    const allavvisi = props.data.allContentfulAvvisi || [];
   
    this.state = {
      allavvisi: allavvisi,
      avvisi: []
    }
  }

  componentDidMount() {
    let allavvisi = this.state.allavvisi;
    let avvisi = [];
    allavvisi.edges.forEach((item,index) => {
      const mdate = Moment(item.node.dataDiScadenza);
      if (mdate.isAfter()) {
        avvisi.push(item);
      }
    });
    this.setState({
      avvisi: avvisi
    });
  }

  render() { 
    const breadcrumb = [{"title":"Area studenti"}];
    const intro = this.props.data.contentfulContenutoGenerico;
    const hero = this.props.data.hero;
    let { avvisi } = this.state;
    const datahero = {
      "title": "Ospedale Veterinario Universitario",
      "subtitle": "Area studenti",
      "img": hero.childImageSharp.fluid,
      "arealink": false 
    };
    
    return (
      <Layout location={this.props.location}>
        <Helmet
           title="Area studenti"
           bodyAttributes={
            { class :'has-hero'}
          }
        />
        <Hero data={datahero}/>

        <div className="container">
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
             
              {avvisi.length>0 &&
                <Avvisi data={avvisi}/>
              }
              
              {!!intro && 
                <div dangerouslySetInnerHTML={{ __html: intro.abstract.childMarkdownRemark.html }} >
                
                </div>
              }
            </div>
            <div className="col-md-4">
              {!!intro.attachment &&
                <DocumentiUtili data={intro.attachment}/>
              }
              {!!intro.testoSpallaDestra &&
                 <div dangerouslySetInnerHTML={{ __html: intro.testoSpallaDestra.childMarkdownRemark.html }} >
                
                 </div>
              }
              <br/><br/>
              {intro.fotogallery.map((item,index) => {
                return <p key={index}> 
                    <img src={item.file.url} alt={item.title} />
                </p>
              })}
            </div>
          </div>
        </div>

        <div className="box-area-subhome box-area-subhome--blue">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                <img src="/images/contents/logounimi.png" alt="" className="box-area-subhome__img" />
                <div className="box-area-subhome__title">Per tutte le informazioni su didattica, corsi e programmi visita il sito dell'Universit&agrave; degli Studi di Milano</div>
              </div>
            </div>
          </div>
        </div>

        <div className="numbers text-center">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="numbers__title">Ogni anno formiamo</h2>
            </div>
          </div>
          <div className="row">
            <div className="offset-md-2 col-md-4">
              <div className="numbers__item">
                <div className="numbers__value">13</div>
                <div className="numbers__label">Specializzandi</div>
                <img src="/images/contents/icona_surgeon.png" alt="Nuovi chirurghi" className="numbers__img" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="numbers__item">
                <div className="numbers__value">143</div>
                <div className="numbers__label">Medici Veterinari</div>
                <img src="/images/contents/icona_patologa.png" alt="Nuovi veterinari" className="numbers__img" />
              </div>
            </div>
            {/*
            <div className="col-md-4">
              <div className="numbers__item">
                <div className="numbers__value">1.500</div>
                <div className="numbers__label">Nuovi patologi</div>
                <img src="/images/contents/icona_patologa.png" alt="Nuovi patologi" className="numbers__img" />
              </div>
            </div>
            */}
          </div>
        </div>
      </div>


      </Layout>
    )
  }

}

export default AreaStudenti;

export const query = graphql`
query AreaStudentiQuery{
  contentfulContenutoGenerico (contentful_id: {eq: "4INJCMJPDieyAYeQ0Oq804"}) {
    fotogallery {
      title
      file {
        url
      }
      fluid(maxHeight: 180) {
        sizes
        src
        srcSet
      }
    }
    testoSpallaDestra {
      childMarkdownRemark {
        html
      }
    }
    attachment {
      title
      file {
        url
      }
    }
    abstract {
      childMarkdownRemark {
        html
      }
    }
  }
  allContentfulAvvisi(sort: {fields: [dataDiScadenza], order:DESC }, filter: {node_locale: {eq: "it-IT"} }) {
    edges {
      node {
        titolo
        friendlyUrl
        dataDiScadenza
      }
    }
  }
  hero: file(relativePath: {eq: "hero-studenti.png"}) {
    childImageSharp{
      fluid(maxWidth: 1920){
        ...GatsbyImageSharpFluid_withWebp
        src
      }
    }
  }
}
`