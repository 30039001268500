import React from 'react'
import { Link } from 'gatsby'
import entity from '../utility/entity';

class Avvisi extends React.Component {

  render() { 
    const data = this.props.data;
   
    return (
      <div className="avvisi">
        <div className="avvisi__bell">
          <i className="fa fa-bell-o" aria-hidden="true"></i>
          <span>Avvisi</span>
        </div>
        <ul>
          {data.map((item,index) => {
            return (
              <li key={index}>
                {item.node.friendlyUrl ? (
                  <Link to={entity.createAvvisoPath(item.node)}>{item.node.titolo}</Link>
                ):(
                  <span>{item.node.titolo}</span>
                )
                
                }
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

}

export default Avvisi;